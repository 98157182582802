import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { format, isToday, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import {
  Schedule, NextAppointment, Section, Appointment, ContentModal
} from './styles';

import ReactHtmlParser from 'react-html-parser';
import { makeStyles, createStyles, Theme, Grid, CircularProgress, Paper, Typography, Button, Container, Box, Chip, withStyles } from '@material-ui/core';
import Content from '../../components/Content';


import { Helmet } from 'react-helmet';
import InnerHTML from 'dangerously-set-html-content';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { IAppointmentDays, IAulaEscolhida, IAulaExperimental } from '../../models/Appointment/IAppointmentDTO';
import { borderRadius } from 'polished';
import LandingPageCalendario from './CalendarioAgendamento';
import { Instagram, WhatsApp } from '@material-ui/icons';


const StyledChip = withStyles({

  label: {
    fontWeight: 500,
  },
})(Chip);

const useStyles = makeStyles((theme: Theme) =>



  createStyles({
    feedback: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
    },
    GridList: {
      margin: '28px 0 !important',
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",
    },
    Paper: {
      padding: '20px',
      marginBottom: '26px',
      marginTop: '32px',
    },
    textMessage: {
      textAlign: 'center',
    },
    textMessageSub: {
      textAlign: 'center',
      color: theme.palette.text.disabled,
      marginBottom: '20px',
    },
    textMessageCustom: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    titleHours: {
      margin: '18px 6px',

    },
    chipHour: {
      fontSize: '1rem',
    },
    listHours: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },

    ChipService: {
      margin: '10px 5px',
    },
    itemService: {
      flex: 1,
    },
    itemServiceName: {
      color: theme.palette.text.disabled,
    },
    itemServiceHour: {
      fontSize: '17px',
    },
    itemServiceButton: {
      textTransform: 'none',
    },
    containerFixo: {
      padding:10,
      maxHeight: '400px',
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        width: 12,
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '8px',
        backgroundColor: "#e7e7e7",
        border: "1px solid #cacaca",
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, .3)',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        backgroundColor: "#363636"
      }
    },

    logoCenter: {
      maxHeight: '47px',
    },
    headerColor: {
      background: 'linear-gradient(to right, ' + theme.palette.primary.main + ' 0%, ' + theme.palette.primary.dark + ' 100%)',
    },
    headerColorBlank: {
      background: "#ffffff",
      color: theme.palette.text.primary,
    },
    divDescricao: {
      marginTop: '20px',
    },
    redeSocialIcon: {
      color: theme.palette.text.hint,
      cursor: "pointer !important",
      margin: '10px',
    },
    descUnidade: {
      color: theme.palette.text.hint,
    }

  })
);


interface IServices {
  id: number;
  name: string;
}

interface IHoursService {
  DataInicio: string;
  DataFim: string;
  Servico: string;
  Id: number | undefined;
}
type RouteParams = {
  id: string
}
type BookDetailProps = RouteComponentProps<RouteParams>;



const LandingPageAgendamento: React.FC<BookDetailProps> = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loadingData, setLoadingData] = useState(false);
  const [lpData, setLpData] = useState<any>();
  const [idLp, setIdLp] = useState("");
  const { tenant, updateTenant } = useAuth();
  const { addToast } = useToast();


  useEffect(() => {
    const idLp = match.params.id;
    if (idLp && tenant) {
      setIdLp(idLp);
      setLoadingData(true);
      api.get('/landingpage', {
        params: {
          name: idLp,
          idt: tenant?.idTenant,
        }
      }
      ).then(retorno => {
        setLoadingData(false);
        if (retorno.data.agradecimento) {
          retorno.data.agradecimento = retorno.data.agradecimento.replace(/\r?\n/g, '<br/>');
        }
        setLpData(retorno.data);
        localStorage.setItem('@HubfitPortal:lp', JSON.stringify(retorno.data));
        tenant.idBranch = retorno.data.idUnidade;

        // tenant.primaryColorTheme = retorno.data.primaryColorTheme;
        // tenant.secundaryColorTheme = retorno.data.secondaryColorTheme;
        // tenant.textColorTheme = retorno.data.textColorTheme;

        // updateTenant(tenant);

        console.log(retorno.data)
      }).catch(err => {

        addToast({
          type: "error",
          title: 'Erro',
          description: 'Não foi possível se conectar ao servidor',
        });
      })
    }
  }, [tenant?.idTenant]);




  return (
    <>
      {lpData && lpData.scripts &&
        <InnerHTML html={lpData?.scripts} />
      }
      <Helmet>
        <title>Agendamento - {tenant?.name}</title>
      </Helmet>
      {loadingData &&
        <Grid container direction="column"
          justify="center"
          alignItems="center" >
          <Grid item xs={12} className={classes.GridList}>
            <CircularProgress size={40} />
          </Grid>
        </Grid>
      }

      <div>
        {!loadingData && lpData && lpData.html &&
          <Container maxWidth="lg">
            <Paper elevation={3} className={classes.Paper} >


              <Grid container spacing={3} direction="row"
                justify="center"
                alignItems="flex-start" >
                <Grid item xs={12} sm={12} md={4}   >
                  <div className={tenant && tenant.appBarPrimaryColor ? classes.headerColor : classes.headerColorBlank}>
                    <img src={tenant?.url_Logo} alt={tenant?.name} className={classes.logoCenter} />
                  </div>
                  <div className={classes.containerFixo}>

                    <Typography gutterBottom color="textPrimary" variant="body2">
                      {ReactHtmlParser(lpData?.descricao)}
                    </Typography>


                  </div>
                </Grid>
                <LandingPageCalendario></LandingPageCalendario>
              </Grid>




            </Paper>
            <Grid container direction="column"
              justify="center"
              alignItems="center" >
              <Grid item xs={12} sm={12}   >
                <Typography gutterBottom color="textPrimary" variant="h4">
                  {tenant.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}   >
                <Typography gutterBottom variant="h4" className={classes.descUnidade}>
                  {tenant.branchs.find(f => f.id == tenant.idBranch)?.phone}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}   >
                <Typography gutterBottom className={classes.descUnidade} variant="subtitle1">
                  {tenant.branchs.find(f => f.id == tenant.idBranch)?.email}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}   >
                <Typography gutterBottom className={classes.descUnidade} variant="subtitle2">
                  {tenant.branchs.find(f => f.id == tenant.idBranch)?.address}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}   >
                <Typography gutterBottom className={classes.descUnidade} variant="subtitle2">
                  {tenant.branchs.find(f => f.id == tenant.idBranch)?.city}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}   >
                {tenant?.instagram &&
                  <a href={tenant.instagram} target="_blank" style={{ cursor: 'pointer' }}><Instagram className={classes.redeSocialIcon} /></a>
                }
                {tenant?.whatsapp &&
                  <a href={tenant.whatsapp} target="_blank" style={{ cursor: 'pointer' }}><WhatsApp className={classes.redeSocialIcon} /></a>
                }
              </Grid>
            </Grid>

          </Container>
        }
      </div>



    </>
  );

}
export default withRouter(LandingPageAgendamento);
